




















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class BaseCallActionBtn extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly tooltip!: string

  @Prop({
    type: Object,
    required: true,
  }) readonly phoneIcon!: Vue.Component
}
